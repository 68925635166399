var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__main_card_container"},[_c('div',{staticClass:"_main_card_header"},[_c('div',{staticClass:"__header_inner admin_header_padding"},[_c('h2',[_vm._v("Settings")]),_c('div',{staticClass:"side_nav_wrapper"},[_c('div',{staticClass:"__side_nav"},[_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/settings/set_sms_voice_rate'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/settings/set_sms_voice_rate')
                .catch((err) => {})}}},[_vm._v(" Set Sms & Voice Rate ")]),(!_vm.disallowedUsers)?_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/settings/chart_of_accounts'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/settings/chart_of_accounts')
                .catch((err) => {})}}},[_vm._v(" Chart of Accounts ")]):_vm._e(),(!_vm.disallowedUsers)?_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/settings/payment_aggregators'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/settings/payment_aggregators')
                .catch((err) => {})}}},[_vm._v(" Payment Gateway ")]):_vm._e()])])])]),_c('div',{staticClass:"main_card_body"},[_c('router-view')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }