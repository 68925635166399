<template>
  <div class="__main_card_container">
    <div class="_main_card_header">
      <div class="__header_inner admin_header_padding">
        <h2>Settings</h2>
        <div class="side_nav_wrapper">
          <div class="__side_nav">
            <button
              class="btn"
              :class="[
                $route.path == '/admin/settings/set_sms_voice_rate'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/settings/set_sms_voice_rate')
                  .catch((err) => {})
              ">
              Set Sms & Voice Rate
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/settings/chart_of_accounts'
                  ? '__side_item_active'
                  : '',
              ]"
              v-if="!disallowedUsers"
              @click="
                $router
                  .replace('/admin/settings/chart_of_accounts')
                  .catch((err) => {})
              ">
              Chart of Accounts
            </button>
            <button
              v-if="!disallowedUsers"
              class="btn"
              :class="[
                $route.path == '/admin/settings/payment_aggregators'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/settings/payment_aggregators')
                  .catch((err) => {})
              ">
              Payment Gateway
            </button>
          </div>
          <!-- <button @click="importFiles" class="__export">
                        export
                    </button> -->
        </div>
      </div>
    </div>
    <div class="main_card_body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  import downloadcsv from "../../../helpers/downloadcsv";
  export default {
    name: "users",
    computed: {
      disallowedUsers() {
        return this.$store.getters["auth/disallowedUsers"];
      },
    },
    methods: {
      importFiles() {
        let id = "";
        let currentRoute = this.$router.currentRoute.path;
        if (currentRoute == "/admin/users/all") {
          id = "0";
        } else if (currentRoute == "/admin/users/corporate") {
          id = "1";
        } else if (currentRoute == "/admin/users/individual") {
          id = "2";
        } else if (currentRoute == "/admin/users/fraudulent") {
          id = "3";
        } else if (currentRoute == "/admin/users/inactive") {
          id = "4";
        }
        store.dispatch("admin/users/importFiles", id).then((res) => {
          downloadcsv(
            res.data,
            `Users_${
              currentRoute.split("/")[3].charAt(0).toUpperCase() +
              currentRoute.split("/")[3].slice(1)
            }`
          );
        });
      },
    },
    mounted() {
      store.dispatch("admin/users/getInactiveUsers");
    },
  };
</script>
